import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProjectVariablesService {

  constructor() {
    this.oauth2 = environment.oauth2;
    this.loginUrl = environment.loginUrl;
    this.clientUrl = environment.clientUrl;
    this.unlockUrl = environment.unlockUrl;
    this.auditFunctionUrl = environment.auditFunctionUrl;
    this.adminUrl =environment.adminUrl;
  }

  public loginUrl = '';
  public oauth2 = '';
  public clientUrl = '';
  public unlockUrl = '';
  public auditFunctionUrl = '';
  public adminUrl = '';
}
