import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loader= new Subject<boolean>();
  loader$ = this.loader.asObservable();
  constructor() { }

  setLoader() {
    this.loader.next(true);
  }

  stopLoader() {
    this.loader.next(false);
  }
}
