import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimName'
})
export class TrimNamePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value.length > 13) {
      value = value.substring(0, 13) + '...';
    }
    return  value;
  }

}
