import { Injectable } from '@angular/core';
import { of, throwError, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
export class ServiceBase {

    approverID = 'S-1-5-21-29236838-1078541931-1330272300-841522'; /// need to hard code this for now

    protected handleError(error: HttpErrorResponse) {
        const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        // todo - I want to add some logging in here soon
        return throwError(errMsg);
    }

}
