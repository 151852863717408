import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CompCommService } from '../../app/services/comp-comm.service';
import { Auth } from '../interface/auth';
import { AuthInfo } from '../interface/auth-info';
import { NtlmInfo } from '../interface/ntlm-info';
import { User } from '../interface/user';
import { ProjectVariablesService } from './project-variables.service';
import { ServiceBase } from './serviceBase';
import { map } from 'rxjs/operators';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { EmployeeFormPermissions, FamilyFormPermissions,
         JwtGroupsFormPermissions, SpecialAccessFormPermissions,
         UnlocksFormPermissions, AdminSiteRoles, PassPolicyFormPermissions } from '../common/rbac-utils/form-permissions.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ServiceBase implements OnInit {

  constructor(
    private pv: ProjectVariablesService,
    public coco: CompCommService,
    private rolesService: NgxRolesService,
    private permissionService: NgxPermissionsService
  ) { super(); }

  loginSights: NtlmInfo = null;
  rightsInfo: Auth = null;
  oktaInfo: AuthInfo = ({ access_token: '', expires_in: '', scope: '', state: '', token_type: '' } as AuthInfo);
  jrrToken: string = null;
  activeUser: User = null;
  user: string = null;
  groups: string[] = [];

  // RBAC
  employeeFormPermissions = EmployeeFormPermissions;
  familyFormPermissions = FamilyFormPermissions;
  unlocksFormPermissions = UnlocksFormPermissions;
  specialAccessFormPermissions = SpecialAccessFormPermissions;
  jwtGroupsFormPermissions = JwtGroupsFormPermissions;
  passPolicyFormPermissions = PassPolicyFormPermissions;

  adminSiteRoles = AdminSiteRoles;

  private activeRolesSubject = new BehaviorSubject<string[]>([]);
  activeRoles$: Observable<string[]> = this.activeRolesSubject.asObservable();

  ntmlSite = '';
  jwtHelper: JwtHelperService = new JwtHelperService();

  sessionTimeoutMsg: string = 'Your session has timed out please refresh your browser';
  notAuhtorizedMsg: string = 'You are not authorized for this feature';

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    this.doOkta();
  }

  public doOkta() {
    const authorizationUrl = '/v1/authorize';
    const url = this.pv.oauth2 + authorizationUrl +
      '?client_id=retail.passplay.admin' +
      '&response_type=token' +
      '&redirect_uri=' + window.location.origin +
      '&state=statestring' +
      '&nonce=nonce';
    window.location.href = url;
  }

  public iniUserInfo() {
    this.jrrToken = this.oktaInfo.access_token;
    const decodedToken = this.jwtHelper.decodeToken(this.oktaInfo.access_token);
    if (decodedToken == null) {
      this.handleErrorLocal();
    } else {
      this.setActiveRole(decodedToken.groups);
      this.user = decodedToken.sub;
    }
  }

    public getBearer(): string {
    return this.jrrToken;
  }

  parseLoginInfo(hashInfo: string): boolean {
    const splitInfo: Array<string> = hashInfo.split('&');

    for (let i = 0; i < splitInfo.length; i++) {
      const innerInfo: Array<string> = splitInfo[i].split('=');
      switch (innerInfo[0]) {
        case '#access_token':
          this.oktaInfo.access_token = innerInfo[1];
          break;
        case 'token_type':
          this.oktaInfo.token_type = innerInfo[1];
          break;
        case 'expires_in':
          this.oktaInfo.expires_in = innerInfo[1];
          break;
        case 'scope':
          this.oktaInfo.scope = innerInfo[1];
          break;
        case 'state':
          this.oktaInfo.state = innerInfo[1];
          break;
      }
    }

    return true;
  }

  makeOptions() {
    return {
      headers: new HttpHeaders({
        Authorization: 'bearer ' + this.getBearer(),
        'Content-Type': 'application/json'
      }),
    };
  }

  public getBearerToken$(): Observable<{}> {
    return this.isTokenExpired$().pipe(
      map((expired) => {
        if (!expired) {
          return this.getBearer();
        } else {
          throw new Error (this.sessionTimeoutMsg);
        }
      })
    );
  }

  public getAdminRole$(): Observable<string[]> {
    const ret = [];

    this.adminSiteRoles.forEach((x) => {
      ret.push(x.role);
    });

    return of(ret);

  }

  public setActiveRole(roles: string[]): void {

    this.rolesService.flushRolesAndPermissions();

    roles.forEach(x => {
      const rolePerms = this.adminSiteRoles.find(y => y.role === x);
      if (rolePerms !== undefined && rolePerms?.permissions !== undefined) {
        this.rolesService.addRoleWithPermissions(x, Object.values(rolePerms.permissions));
      }
    });

    this.activeRolesSubject.next(roles);

  }

  public loadGroupsFromJwt(): void {

    this.iniUserInfo();

  }

  public hasPermission(permission: string): boolean {

    const perm = this.permissionService.getPermission(permission) ?? null;
    return perm !== null;

  }
   /* istanbul ignore next */
  private handleErrorLocal<T>(result?: T) {
    return (): Observable<T> => of(result as T);
  }

  public isTokenExpired$(): Observable<boolean> {
    if (this.jwtHelper.isTokenExpired(this.getBearer())) {
      return of(true);
    } else {
      return of(false);
    }
  }

}
