import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  toasts: any[] = [];
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  public setError(err: string) {
    this.show(err, { classname: 'alert-danger' });
  }

  public setSuccess(txt: string) {
    this.show(txt , { classname: 'alert-success' });
  }

  public setWarning(txt: string) {
    this.show(txt , { classname: 'alert-warn' });
  }

  public remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

}
