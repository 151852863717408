<header class="bg-dark border-3-grey pb-2">
  <ul ngbNav #nav="ngbNav" [activeId]="1" class="text-white" [roles]="false">
    <li [ngbNavItem]="1" ngbDropdown class="nav-item pl-3">
      <a (click)="false" class="small text-capitalize">{{ 'LANGUAGE' | translator }} : </a>
      <a (click)="false" class="small" ngbDropdownToggle>{{selectedLanguage.name}}</a>
      <div ngbDropdownMenu>
        <button ngbDropdownItem *ngFor="let language of languages" (click)="setLanguage(language, false)">
          <h6 class="small">{{language.name}}</h6>
        </button>
      </div>
    </li>
    <!-- User information -->
    <li class="nav-item pr-3 ml-sm-auto small" *ngIf="isLoggedIn">
      <img
        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAMCAYAAAC0qUeeAAAAAXNSR0IArs4c6QAAAK9JREFUKBVjYEAC////jwTie0D8AYiXAzEPkjSCCZQQAeLvQIwMqhAqGBiYkDhmQDYHEh/EtEfmIyu2RJaAso2B1rDBxJEVf4AJItFfgOw/SHwIE2gCOxA/Q3YwkB2LoRAmAJT0QFK8DSYOo5GdARKDuw/IZgFqZIYpBNNAAZD1qUC8Goh/AzEyuArkNAOxJkxxF7IsDvZboDgPyBmeKFZh5wgBhc1BimWxy2OIygAAK0Cm2nTt83oAAAAASUVORK5CYII='
        alt="user icon" class="d-sm-inline d-none" />
        {{getUserName()}}
    </li>
  </ul>
</header>