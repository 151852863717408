import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html'
})
export class LandingComponent implements OnInit {
  constructor(
    private auth: AuthService
  ) { }

  /* istanbul ignore next */
  ngOnInit() {
  }

  isLoggedIn(): boolean {
    return (!(this.auth.user === null));
  }
}
