import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  isLoading: boolean;
  constructor(private loaderService: LoaderService,private changeDetector: ChangeDetectorRef) {
    loaderService.loader$.subscribe(res => {
      this.isLoading = res;
      this.changeDetector.detectChanges();
    });
   }

  ngOnInit(): void {
  }

}
