import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, ObservableInput, EMPTY } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { mergeMap, catchError, switchMap, map, finalize } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  pendingRequestsCount = 0;
  constructor(private auth: AuthService, private alert: AlertService, private loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.setLoader();

    return this.auth.getBearerToken$().pipe(
      mergeMap(token => {
        let httpOptions ={};
        if (req.body instanceof FormData) {
          httpOptions = { Authorization: `Bearer ${token}`, enctype:'multipart/form-data'};
        }
        else {
          httpOptions = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'};
        }
        const tokenReq = req.clone({
          setHeaders: httpOptions
        });
        this.pendingRequestsCount++;
        return next.handle(tokenReq).pipe(finalize(() => {
        this.pendingRequestsCount--;
        if(this.pendingRequestsCount ===0){
        this.loaderService.stopLoader();
        }
        }));
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err?.error?.error?.message) {
            this.alert.setError(err.error.error.message);
            return EMPTY;
          }
          if (err?.error && typeof (err?.error) === 'string') {
            this.alert.setError(err?.error);
            return throwError(err);
          } else {
            this.alert.setError('Error while processing your request. Please reach out to #passplay-devops for help.');
            return throwError(err);
          }
        } else {
          if (err.message === this.auth.sessionTimeoutMsg) {
            this.alert.setError(err.message);
            return EMPTY;
          }
        }
      })
    );
  }
}
