import { Component, OnInit, TemplateRef, HostBinding, NgModule} from '@angular/core';
import {Subject} from 'rxjs';
import { AlertService } from '../../services/alert.service';
@Component({
  selector: 'app-toasts',
  templateUrl: './toaster.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class.ngb-toasts]': 'true'}
})

export class ToasterComponent implements OnInit {
  constructor(public toastService: AlertService) {}
  private success = new Subject<string>();
  private error = new Subject<string>();
  successMessage: string;
  errorMessage: string;
  alertType: string;

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
  ngOnInit(): void {
  }
}
