<ngb-toast
    *ngFor="let toast of toastService.toasts"
    [class]="toast.classname"
    [autohide]="true"
    [delay]="toast.delay || 10000"
    (hide)="toastService.remove(toast)">
    <div class="d-flex justify-content-between">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>{{ toast.textOrTpl }}
    </ng-template>
        <a aria-label="Close" style="cursor: pointer;" (click)="toastService.remove(toast)" >
            <span aria-hidden >
                <strong>&times;</strong>
            </span>
        </a>
    </div>
</ngb-toast>