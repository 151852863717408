import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { CompCommService } from '../../services/comp-comm.service';
import { LanguageService } from '../../services/language.service';
import { Language } from '../../interface/language';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  languages: Language[] = null;
  selectedLanguage: Language = null;
  constructor(public auth: AuthService,
              public coco: CompCommService,
              public languageService: LanguageService) { }

  ngOnInit() {
    this.languages = this.languageService.getAvailableLanguages();
    const defaultLanguage = this.languageService.getDefaultLanguage();
    this.setLanguage(defaultLanguage, true);
  }

  getUserName() {
    return (this.auth.user != null ? this.auth.user : '');
  }

  isLoggedIn(): boolean {
    return (this.auth.user != null ? true : false);
  }

  setLanguage(language: Language, onLoad: boolean) {
    this.selectedLanguage = language;
    this.languageService.setLanguage(language, onLoad);
  }
}
