import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '../interface/language';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'translator'
})
export class TranslatorPipe implements PipeTransform {
  constructor(private languageService: LanguageService) { }
  transform(value: any, args?: any): any {

    const language: Language = this.languageService.getDefaultLanguage();

    if (language.translations[value] !== undefined) {
      return language.translations[value];
    } else {
      return '';
    }
  }

}
