import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { AlertService } from '../../services/alert.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  constructor(private alertSvc: AlertService){}
  handleError(error: HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      console.log(error);
    } else {
      //this.alertSvc.setError(`${error}`);
      throw error;
    }
  }
}
