import { Pipe, PipeTransform } from '@angular/core';
import { Relationship } from '../enums/relationship.enum';

@Pipe({
  name: 'relationshipDisplay'
})
export class RelationshipDisplayPipe implements PipeTransform {

  transform(value: Relationship, args?: any): any {
    if (value == null) {
      return;
    }
    return  Relationship[value];
  }

}
