import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { HttpClient, } from '@angular/common/http';

import { ProjectVariablesService } from '../services/project-variables.service';
import { Sponsor } from '../interface/sponsor';
import { Pass } from '../interface/pass';
import { PassPolicy } from '../interface/pass-policy';
import { ServiceBase } from './serviceBase';
import { AuthService } from './auth.service';
import { RegistrationLink } from '../interface/registrationLink';

@Injectable({
  providedIn: 'root'
})
export class SponsorService extends ServiceBase {

  constructor(
    private http: HttpClient,
    private projVars: ProjectVariablesService,
  ) { super(); }

  public findSponsorByEmployeeId(employeeId: string): Observable<Sponsor> {
    let sponsor: Sponsor = null;
    let policyObservable: Observable<PassPolicy[]> = null;
    const clientUrl = `${this.projVars.clientUrl}/v1/Sponsor/ExternalId/${employeeId}/AdLookUp`;
    return this.http.get<Sponsor>(clientUrl)
      .pipe(
        concatMap(data => {
          sponsor = data;
          const passUrl = `${this.projVars.clientUrl}/v1/sponsor/${sponsor.sponsorId}/EmployeePasses`;
          const policyUrl = `${this.projVars.clientUrl}/v1/sponsor/${sponsor.sponsorId}/PassPolicies`;
          // might as well request both at the same time
          policyObservable = this.http.get<PassPolicy[]>(policyUrl);
          return this.http.get<Pass[]>(passUrl);
        }),
        concatMap(data => {
          sponsor.passes = data;
          return policyObservable;
        }),
        concatMap(data => {
          // not sure if we really want to filter the passes or not, but for now ...
          sponsor.policies = data.filter(policy => policy.passTypeId % 100 === 1);
          return of(sponsor);
        }),
        catchError(err => {
          if (err.status === 404) {
            return of(null);
          }
          throw err;
        }));
  }

  public getSponsorPassPolicies(sponsorId: number): Observable<PassPolicy[]> {
    const policyUrl = `${this.projVars.clientUrl}/v1/sponsor/${sponsorId}/PassPolicies`;
    return this.http.get<PassPolicy[]>(policyUrl)
      .pipe(
        catchError(err => {
          if (err.status === 404) {
            return of(null);
          }
          throw err;
        }));
  }

  public saveSponsor(sponsor: Sponsor, activePasses: Pass[], inactivePasses: Pass[], comment: string): Observable<any> {
    const calls = [];

    const saveSponsorUri = `${this.projVars.clientUrl}/v1/sponsor/${sponsor.sponsorId}`; // PUT
    const sponsorDto = this.makeSponsorDto(sponsor, comment);
    const sponsorObs = this.http.put(saveSponsorUri, JSON.stringify(sponsorDto));
    calls.push(sponsorObs);

    // todo instead of calling the following here, we should probably
    // call savePasses in the pass service.
    if (activePasses) {
      const savePassesUri = `${this.projVars.clientUrl}/v1/sponsor/${sponsor.sponsorId}/passes`; // POST
      const passesDto = this.makePassesDto(activePasses, comment);
      const passesObs = this.http.post(savePassesUri, JSON.stringify(passesDto));
      calls.push(passesObs);
    }
    if (inactivePasses) {
      inactivePasses.forEach(pass => {
        const inactiveUri = `${this.projVars.clientUrl}/v2/pass/${pass.passId}/inactive`; // PUT
        const inactivePassDto = this.makeInactivePassDto(comment);
        const inactiveObs = this.http.put(inactiveUri, JSON.stringify(inactivePassDto));
        calls.push(inactiveObs);
      });

    }

    return forkJoin(calls)
      .pipe(
        // tap( res => console.log(`RES: ${JSON.stringify(res)}`)),
        catchError(err => {
          throw err;
        })
      );
  }

  public findSponsorBySearchTerm(searchTerm: string): Observable<Sponsor[]> {
    const clientUrl = `${this.projVars.clientUrl}/v1/Search/sponsors?partial=${searchTerm}&maxRows=100`;
    return this.http.get<Sponsor[]>(clientUrl)
      .pipe(
        catchError(err => this.handleError(err))
      );
  }

  public findFamilyBySearchTerm(searchTerm: string): Observable<Pass[]> {
    const clientUrl = `${this.projVars.clientUrl}/search/pass/family/v2?partial=${searchTerm}&maxRows=100`;
    return this.http.get<Pass[]>(clientUrl)
      .pipe(
        catchError(err => this.handleError(err))
      );
  }

  public getRegistrationLink(sponsorId: number, passId: number): Observable<RegistrationLink> {
    const regLinkUrl = `${this.projVars.clientUrl}/v1/sponsor/${sponsorId}/Passes/${passId}/RegistrationLink`;
    return this.http.get<RegistrationLink>(regLinkUrl)
      .pipe(
        catchError(err => {
          if (err.status === 404) {
            return of(null);
          }
          throw err;
        }));
  }

  private makeSponsorDto(sponsor: Sponsor, updateComment: string = null) {
    const dto = {
      firstName: sponsor.firstName,
      lastName: sponsor.lastName,
      externalId: sponsor.externalId,
      sponsorId: sponsor.sponsorId,
      sponsorTypeId: sponsor.sponsorTypeId,
      statusId: sponsor.statusId,
      policyLocationId: sponsor.policyLocationId,
      country: sponsor.country,
      suspensionEndDate: sponsor.suspensionEndDate,
      comment: updateComment
    };
    return dto;
  }

  private makePassesDto(passes: Pass[], updateComment: string = null) {
    let dto = null;
    dto = {
      approver: this.approverID,
      guestPasses: passes,
      comment: updateComment
    };
    return dto;
  }

  private makeInactivePassDto(updateComment: string) {
    const dto = {
      comment: updateComment
    };
    return dto;
  }
}
