export enum Relationship {
  brother = 1,
  brotherInLaw = 2,
  daughter = 3,
  father = 4,
  fatherInLaw = 5,
  grandfather = 6,
  grandfatherInLaw = 7,
  grandmother = 8,
  grandmotherInLaw = 9,
  mother = 10,
  motherInLaw = 11,
  sister = 13,
  sisterInLaw = 14,
  son = 15,
  spousePartner = 16,
  sonInLaw = 17,
  daughterInLaw = 18,
  stepBrother = 19,
  stepSister = 20,
  stepMotherInLaw = 21,
  stepFatherInLaw = 22,
  stepMother = 23,
  stepFather = 24,
  stepSon = 25,
  stepDaughter = 26,
  child = 27,
  grandchild = 28,
  grandDaughter = 29,
  grandSon = 30

}
