export enum EmployeeFormPermissions {
    viewEmployee = 'viewEmployeeForm',
    findEmployee = 'findEmployeeForm',
    resendEmail = 'resendEmailEmployeeForm',
    editEmployeeForm = 'editEmployeeForm'
}
export enum FamilyFormPermissions {
    viewFamily = 'viewFamilyForm',
    searchEmployee = 'searchEmployeeFamilyForm',
    searchFamily = 'searchFamilyForm',
    addFamily = 'addFamilyForm',
    viewFamilyDetails = 'viewFamilyDetailsForm',
    editFamilyDetails = 'editFamilyDetailsForm',
    resendEmail = 'resendEmailFamilyForm'
}
export enum SpecialAccessFormPermissions {
    viewSpecialAccess = 'viewSpecialAccessForm'
}

export enum UnlocksFormPermissions {
    viewUnlocks = 'viewUnlocksForm',
    addUnlocks = 'addUnlocksForm',
    viewSearchButton = 'viewSearchButton' // tmeporary setting until feature is fully baked
}

export enum JwtGroupsFormPermissions {
    viewJwtGroups = 'viewJwtGroupsForm'
}

export enum PassPolicyFormPermissions{
  viewPassPolicy='ViewPassPolicy'
}

export const AdminSiteRoles = [
    {
      role: 'App.PassPlay.Admin.User.Write',
      permissions: [
        EmployeeFormPermissions.viewEmployee,
        EmployeeFormPermissions.editEmployeeForm,
        EmployeeFormPermissions.findEmployee,
        EmployeeFormPermissions.resendEmail,
        FamilyFormPermissions.viewFamily,
        FamilyFormPermissions.viewFamilyDetails,
        FamilyFormPermissions.searchEmployee,
        FamilyFormPermissions.searchFamily,
        FamilyFormPermissions.resendEmail,
        FamilyFormPermissions.editFamilyDetails,
        FamilyFormPermissions.addFamily]
    },
    {
      role: 'App.PassPlay.Admin.User.ReadOnly',
      permissions: [
        EmployeeFormPermissions.viewEmployee,
        EmployeeFormPermissions.findEmployee,
        EmployeeFormPermissions.resendEmail,
        FamilyFormPermissions.viewFamily,
        FamilyFormPermissions.viewFamilyDetails,
        FamilyFormPermissions.searchEmployee,
        FamilyFormPermissions.searchFamily,
        FamilyFormPermissions.resendEmail]
    },
    {
      role: 'App.PassPlay.Admin.SpecialAccess',
      permissions: [SpecialAccessFormPermissions.viewSpecialAccess]
    },
    {
      role: 'App.PassPlay.Offers.User.ReadOnly',
      permissions: [
        UnlocksFormPermissions.viewUnlocks,
        UnlocksFormPermissions.addUnlocks,
        UnlocksFormPermissions.viewSearchButton]
    },
    {
      role: 'App.PassPlay.Offers.User.Write',
      permissions: [
        UnlocksFormPermissions.viewUnlocks,
        UnlocksFormPermissions.addUnlocks,
        UnlocksFormPermissions.viewSearchButton]
    },
    {
      role: 'App.CS_CRM.GeoComp.NA_CNX_CR1',
      permissions: [
        EmployeeFormPermissions.viewEmployee,
        EmployeeFormPermissions.findEmployee,
        EmployeeFormPermissions.resendEmail,
        FamilyFormPermissions.resendEmail,
        FamilyFormPermissions.viewFamily,
        FamilyFormPermissions.viewFamilyDetails,
        FamilyFormPermissions.searchEmployee,
        FamilyFormPermissions.searchFamily]
    },
    {
      role: 'Nike.Support.CSR.Global',
      permissions: [
        EmployeeFormPermissions.viewEmployee,
        EmployeeFormPermissions.findEmployee,
        EmployeeFormPermissions.resendEmail,
        FamilyFormPermissions.viewFamily,
        FamilyFormPermissions.viewFamilyDetails,
        FamilyFormPermissions.searchEmployee,
        FamilyFormPermissions.searchFamily,
        FamilyFormPermissions.resendEmail]
    },
    {
      role: 'Retail.GB.PassPlay.Engineering.Users',
      permissions: [
        JwtGroupsFormPermissions.viewJwtGroups,
        UnlocksFormPermissions.viewSearchButton]
    },
    {
      role: 'App.PassPlay.Admin.PassPolicy',
      permissions: [PassPolicyFormPermissions.viewPassPolicy]
    }
  ];
