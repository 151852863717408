import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

export const environment = {
  adminUrl: 'https://n8-admin-cnnorth3-ae.n8-passplay-ase-cnn3.appserviceenvironment.cn',
  local: false,
  loginUrl: '',
  oauth2: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  production: true,
  clientUrl: 'https://sponsorex-test.nike.com.cn', //'http://localhost:5000',
  unlockUrl: 'https://n8-offerservice-fn.n8-passplay-ase-cnn3.appserviceenvironment.cn',
  auditFunctionUrl: 'https://sponsorex-test.nike.com.cn',
  version: '#{Build.BuildNumber}#',
  plugins: [NgxsReduxDevtoolsPluginModule.forRoot()],
  country:'china'
};
