import { Injectable } from '@angular/core';
import { PurchaseHistory } from '../interface/purchaseHistory';
import { HttpClient } from '@angular/common/http';
import { ProjectVariablesService } from './project-variables.service';
import { catchError } from 'rxjs/operators';
import { ServiceBase } from './serviceBase';
import { Observable,forkJoin} from 'rxjs';
import { StatusHistory } from '../interface/statusHistory';

@Injectable({
  providedIn: 'root'
})
export class HistoryDataService extends ServiceBase {
  constructor(
    private http: HttpClient,
    private projVars: ProjectVariablesService,
  ) { super(); }

  public getPurchaseData(visitorId: number): Observable<PurchaseHistory[]> {
    // const httpOptions = this.auth.makeOptions();
    const clientUrl = `${this.projVars.clientUrl}/visitor/purchase_history/v1/${visitorId}`;
    return this.http.get<PurchaseHistory[]>(clientUrl)
     .pipe(
      catchError(err => this.handleError(err))
    );
  }

  public getStatusHistoryData(auditType: string, auditId: string): Observable<StatusHistory[]> {
    // const httpOptions = this.auth.makeOptions();
    const auditUrl = `${this.projVars.auditFunctionUrl}/api/audit/log/v1?type=${auditType}&auditId=${auditId}`;
    return this.http.get<StatusHistory[]>(auditUrl)
      .pipe(
        catchError(error => this.handleError(error))
      );
  }

  public getSponsorandPassStatusHistoryData(auditType: string, auditId: string): Observable<any> {
    const auditArray: string[] = auditType.split(',');
    const auditIdArray: string[] = auditId.split(',');
    const calls =[];
    for(let i: number=0;i<auditArray.length;i++)
    {
      if(+auditIdArray[i]>0)
      {
      let auditUrl = '';
        switch (auditArray[i])
        {
          case 'Sponsor':{
            auditUrl = `${this.projVars.auditFunctionUrl}/api/audit/log/v1?type=${auditArray[i]}&auditId=${auditIdArray[i]}`;
            break;
          }
          case 'Store':{
            auditUrl = `${this.projVars.auditFunctionUrl}/api/audit/log/v1?type=Pass&auditId=${auditIdArray[i]}`;
            break;
          }
          case 'Swoosh':{
            auditUrl = `${this.projVars.auditFunctionUrl}/api/audit/log/v1?type=Pass&auditId=${auditIdArray[i]}`;
            break;
          }
          case 'Converse':{
            auditUrl = `${this.projVars.auditFunctionUrl}/api/audit/log/v1?type=Pass&auditId=${auditIdArray[i]}`;
            break;
          }
        }
        if(auditUrl.length>0)
        {
          calls.push(this.http.get<StatusHistory[]>(auditUrl));
        }
      }
    }

    return forkJoin(calls)
      .pipe(
        catchError(error => this.handleError(error))
      );
  }
}
