import { Injectable } from '@angular/core';
import { Language } from '../interface/language';
import EnglishJson from '../languages/en.json';
import JapaneseJson from '../languages/ja.json';
import ChineseJson from '../languages/zh-Hans.json';
import KoreanJson from '../languages/ko.json';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  languageFilePath: string = '../languages/';
  language: Language = null;
  languages: Language[] = [{ name: 'English', translationFile: 'en.json', translations: EnglishJson },
  { name: 'Japanese', translationFile: 'ja.json', translations: JapaneseJson },
  { name: 'Simplified Chinese', translationFile: 'zh-Hans.json', translations: ChineseJson },
  { name: 'Korean', translationFile: 'ko.json', translations: KoreanJson }];

  public getDefaultLanguage() {
    const preferredLanguage = localStorage.getItem('preferred-language');

    if (preferredLanguage) {
      return this.languages.find(x => x.name === preferredLanguage);
    }

    return this.languages.find(x => x.name === 'English');
  }

  public getAvailableLanguages() {
    return this.languages;
  }

  public setLanguage(selectedLanuage: Language, onLoad: boolean) {
    this.language = selectedLanuage;

    if (!onLoad) {
      localStorage.setItem('preferred-language', this.language.name);
      window.location.reload();
    }
  }
}
