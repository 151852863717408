export enum VisitStatus {
  pending = 0,
  registered = 1,
  declined = 2,
  canceled = 3,
  used = 4,
  expired = 5,
  denied = 6,
  inactive = 7,
  checkedIn = 8,
  suspended = 9
}
