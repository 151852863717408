import { Pipe, PipeTransform } from '@angular/core';
import { VisitorStatus } from '../enums/visitor-status.enum';


@Pipe({
    name: 'goldCardStatus'
  })
  export class GoldcardStatusPipe implements PipeTransform {
    transform(value: VisitorStatus, args?: any): any {
      if (value == null) {
        return;
      }
      return  VisitorStatus[value];
    }
  }
