import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslatorPipe } from './../../pipes/translator.pipe';
import { GoldcardStatusPipe } from './../../pipes/goldcard-status.pipe';
import { RelationshipDisplayPipe } from './../../pipes/relationship-display.pipe';
import { TrimNamePipe } from './../../pipes/trim-name.pipe';
import { DateFormatPipe } from './../../pipes/date-format.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PassService } from './../../services/pass.service';
import { SponsorService } from './../../services/sponsor.service';
import { AlertService } from './../../services/alert.service';
import { AuthService } from './../../services/auth.service';
import { HistoryDataService } from './../../services/history-data.service';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BindFormDirective } from '../../common/directives/bind-form.directive';
import { CustomErrorHandler } from '../../common/utilities/custom-error-handler';
import { PreventDoubleClickDirective } from '../../common/directives/prevent-double-click.directive';

@NgModule({
    imports: [ReactiveFormsModule,
        NgbModule,
        FontAwesomeModule,
        NgSelectModule,
        CommonModule,
        ClipboardModule
    ],
    declarations: [TranslatorPipe,
        GoldcardStatusPipe,
        RelationshipDisplayPipe,
        TrimNamePipe,
        DateFormatPipe,
        BindFormDirective,
        PreventDoubleClickDirective
    ],
    exports: [
        TranslatorPipe,
        GoldcardStatusPipe,
        RelationshipDisplayPipe,
        TrimNamePipe,
        DateFormatPipe,
        ReactiveFormsModule,
        NgbModule,
        NgxPermissionsModule,
        FontAwesomeModule,
        CommonModule,
        ClipboardModule,
        BindFormDirective,
        PreventDoubleClickDirective
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [TranslatorPipe,
                GoldcardStatusPipe,
                RelationshipDisplayPipe,
                TrimNamePipe,
                DateFormatPipe,
                PassService,
                SponsorService,
                AlertService,
                AuthService,
                HistoryDataService,
                {provide: ErrorHandler, useClass: CustomErrorHandler}
            ]
        };
    }
}
