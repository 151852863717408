import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let format = 'MM/DD/YY';
    switch (value.toUpperCase()) {
      // yyyy/m/d
      case 'CHINA':
      case 'CN': // China
      case 'CHN': // China
      case 'HONG KONGong S.A.R.':
      case 'HONG KONG SAR':
      case 'JAPAN':
      case 'JP': // Japan
      case 'MACAU SAR':
      case 'TAIWAN':
        format = 'yyyy/M/d';
        break;

      // yyyy.mm.dd
      case 'KR': // Korea
      case 'KOREA':
      case 'KOREA, REPUBLIC OF':
      case 'HUNGARY':
      case 'HU': // Hungary
        format = 'yyyy.M.d';
        break;

      // dd.mm.yyyy
      case 'AUSTRIA':
      case 'CZECH REPUBLIC':
      case 'CROATIA':
      case 'DENMARK':
      case 'GERMANY':
      case 'DE': // Germany
      case 'NORWAY':
      case 'NO': // Norway
      case 'POLAND':
      case 'ROMANIA':
      case 'RUSSIA':
      case 'SWITZERLAND':
      case 'TURKEY':
      case 'TR':  // Turkey
        format = 'd.M.yy';
        break;

      // dd-mm-yyyy
      case 'CANNADA':
      case 'CANADA':
      case 'CA':
      case 'SWEDEN':
      case 'SE': // Sweden
        format = 'd-M-yy';
        break;

      // dd/mm/yyyy
      case 'ARGENTINA':
      case 'ARG': // Argentina
      case 'AUSTRALIA':
      case 'BELGIUM':
      case 'BE': // Belgium.
      case 'BRAZIL':
      case 'BR': // Brazil
      case 'CHILE':
      case 'CL': // Chile
      case 'COSTA RICA':
      case 'FRANCE':
      case 'FR': // France
      case 'GB':  // Great Britain
      case 'GREECE':
      case 'GR': // Greece
      case 'INDIA':
      case 'IN': // India
      case 'INDONESIA':
      case 'IRELAND':
      case 'ISRAEL':
      case 'ITALY':
      case 'IT': // Italy
      case 'MALAYSIA':
      case 'MEXICO':
      case 'MX':
      case 'NETHERLANDS':
      case 'NETHERLANDS, THE':
      case 'NL': // Netherlands
      case 'NEW ZEALAND':
      case 'PORTUGAL':
      case 'SINGAPORE':
      case 'SG': // Singapore
      case 'SPAIN':
      case 'ES': // Spain
      case 'SRI LANKA':
      case 'SOUTH AFRICA':
      case 'THAILAND':
      case 'UNITED KINGDOM':
      case 'URUGUAY':
      case 'VIET NAM':
        format = 'd/M/yy';
        break;

      // mm/dd/yyyy
      case 'PHILIPPINES':
      case 'UNITED STATES':
      case 'UNITEDSTATES':
      case 'US':
      case 'USA':
        format = 'M/d/yy';
        break;

      default:
        format = 'MM/DD/YY';
        break;
    }

    return format;
  }

}
