import { Injectable } from '@angular/core';
import { Sponsor } from '../interface/sponsor';
import { VisitStatus } from '../enums/visit-status.enum';
import { PassPolicy } from '../interface/pass-policy';
import { HttpClient } from '@angular/common/http';
import { ProjectVariablesService } from './project-variables.service';
import { catchError, map } from 'rxjs/operators';
import { ServiceBase } from './serviceBase';
import { Pass } from '../interface/pass';
import { Observable, forkJoin, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PassService extends ServiceBase {

  constructor(
    private http: HttpClient,
    private projVars: ProjectVariablesService,
  ) { super(); }

  public resendEmail(passId: number) {
    const resendEmailUrl = `${this.projVars.clientUrl}/v1/passes/${passId}/resend`;
    return this.http.put(resendEmailUrl, null)
      .pipe(
        catchError(err => this.handleError(err))
      );
  }

  public getFamilyPassesBySponsor(sponsorId: string): Observable<Pass[]> {
    const clientUrl = `${this.projVars.clientUrl}/v1/sponsor/${sponsorId}/FamilyPasses`;
    return this.http.get<Pass[]>(clientUrl)
     .pipe(
      catchError(err => this.handleError(err))
    );
  }

  public getEmployeePasses(sponsorId: string): Observable<Pass[]> {

    const passUrl = `${this.projVars.clientUrl}/v1/sponsor/${sponsorId}/EmployeePasses`;
    return this.http.get<Pass[]>(passUrl)
      .pipe(
        catchError(err => this.handleError(err))
      );
  }
  public getReservationReport(passPolicyId: number): Observable<any> {
    const passRoute = '/api/pass';
    const passUrl = `${this.projVars.adminUrl}${passRoute}/report/${passPolicyId}`;
    return this.http.get<any>(passUrl,{responseType: 'blob' as 'json', observe:'response'})
    .pipe(
      catchError(err => this.handleError(err))
    );
  }
  public savePasses(sponsorId: number, activePasses: Pass[], inactivePasses: Pass[], comments: string): Observable<any> {
    const calls = [];

    if (activePasses && activePasses.length > 0) {
      const savePassesUri = `${this.projVars.clientUrl}/v1/sponsor/${sponsorId}/passes`; // POST
      const passesDto = {
        approver: this.approverID,
        guestPasses: activePasses,
        comment: comments
      };
      const passesObs = this.http.post(savePassesUri, JSON.stringify(passesDto));
      calls.push(passesObs);
    }
    if (inactivePasses && inactivePasses.length > 0) {
      inactivePasses.forEach( pass => {
        const inactiveUri = `${this.projVars.clientUrl}/v2/pass/${pass.passId}/inactive`; // PUT
        const passDto = {
          Comment: comments
        };
        const inactiveObs = this.http.put(inactiveUri, passDto);
        calls.push(inactiveObs);
      });
    }

    if (calls.length > 0) {
    return forkJoin(calls)
      .pipe(
        // tap( res => console.log(`RES: ${JSON.stringify(res)}`)),
        catchError( err => {
          throw err;
        })
      );
    } else {
      return of(1);
    }
  }

  public createPass(policy: PassPolicy, sponsor: Sponsor) {
    return {
      guestEmailLanguage: null,
      passId: null,
      visitorId: null,
      alternateId: null,
      passTypeId: policy.passTypeId,
      passTypeToken: null,
      passPolicyId: policy.passPolicyId,
      passPolicy: null,
      passLocationId: 0,
      passLocation: null,
      firstName: sponsor.firstName,
      lastName: sponsor.lastName,
      name: sponsor.firstName + ' ' + sponsor.lastName,
      emailAddress: null,
      shoppingDateStart: policy.shoppingStartDate,
      shoppingDateEnd: policy.shoppingEndDate,
      sponsorId: sponsor.sponsorId,
      employeeId: sponsor.externalId,
      employeeName: null,
      passStatusId: policy.approvalFlag ? VisitStatus.pending : VisitStatus.registered,
      statusId: policy.approvalFlag ? VisitStatus.pending : VisitStatus.registered,
      statusToken: null,
      statusTimestamp: null,
      companyName: null,
      relationshipId: null,
      relationshipToken: null,
      compAmount: null,
      compNumber: null,
      noteFlagId: null,
      discount: 0,
      approverName: null,
      approved: false,
      passPlayId: null,
      passPlayIdRegistered: null,
    } as Pass;
  }
}
