import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompCommService {

  public passData: any[] = [];
  private errorResponse: BehaviorSubject<any[]>;
  private alertResponse: BehaviorSubject<any[]>;
  private saveFunction: Function;

  // this can be used for any from you need to test is valed and set save buttons
  private fromResponse: BehaviorSubject<any[]>;

  private alertPopTxt = '';
  constructor() {
    this.errorResponse = new BehaviorSubject<any[]>(['', false, '']);
    this.alertResponse = new BehaviorSubject<any[]>(['']);
    this.fromResponse = new BehaviorSubject<any[]>([null, false]);
  }

  // this is work for showing errors on the page
  /* istanbul ignore next */
  public getError(): Observable<any[]> {
    return this.errorResponse.asObservable();
  }

  /* istanbul ignore next */
  public setError(txt: string, canClose: boolean = false, error: string) {
    this.errorResponse.next([txt, canClose, error]);
  }

  /* istanbul ignore next */
  public popAlert(): Observable<any[]> {
    return this.alertResponse.asObservable();
  }

  /* istanbul ignore next */
  public cleanAlert() {
    this.alertResponse.next(['']);
  }

  /* istanbul ignore next */
  public showAlert(at: string = '') {
    this.alertPopTxt = at;
    this.alertResponse.next([this.alertPopTxt]);
    this.alertPopTxt = '';
  }
}
